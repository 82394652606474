import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetCampaignWidgetEnabledMutationVariables = Types.Exact<{
  value: Types.Scalars['Boolean'];
  programId: Types.Scalars['ID'];
}>;


export type SetCampaignWidgetEnabledMutation = { __typename: 'Mutations', setCampaignWidgetEnabled?: { __typename: 'SetCampaignWidgetEnabled', ok: boolean } | null };


export const SetCampaignWidgetEnabledDocument = gql`
    mutation SetCampaignWidgetEnabled($value: Boolean!, $programId: ID!) {
  setCampaignWidgetEnabled(value: $value, programId: $programId) {
    ok
  }
}
    `;
export type SetCampaignWidgetEnabledMutationFn = Apollo.MutationFunction<SetCampaignWidgetEnabledMutation, SetCampaignWidgetEnabledMutationVariables>;

/**
 * __useSetCampaignWidgetEnabledMutation__
 *
 * To run a mutation, you first call `useSetCampaignWidgetEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCampaignWidgetEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCampaignWidgetEnabledMutation, { data, loading, error }] = useSetCampaignWidgetEnabledMutation({
 *   variables: {
 *      value: // value for 'value'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useSetCampaignWidgetEnabledMutation(baseOptions?: Apollo.MutationHookOptions<SetCampaignWidgetEnabledMutation, SetCampaignWidgetEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCampaignWidgetEnabledMutation, SetCampaignWidgetEnabledMutationVariables>(SetCampaignWidgetEnabledDocument, options);
      }
export type SetCampaignWidgetEnabledMutationHookResult = ReturnType<typeof useSetCampaignWidgetEnabledMutation>;
export type SetCampaignWidgetEnabledMutationResult = Apollo.MutationResult<SetCampaignWidgetEnabledMutation>;
export type SetCampaignWidgetEnabledMutationOptions = Apollo.BaseMutationOptions<SetCampaignWidgetEnabledMutation, SetCampaignWidgetEnabledMutationVariables>;