import { Box, Typography, createStyles, makeStyles } from '@material-ui/core'
import { EcommWidgetPosition } from '../../../gql-global'
import React, { useState } from 'react'
import theme, { primary } from '../../../loudcrowd-theme'
import { useFormikContext } from 'formik'
import { ProgramSettingsFields } from './CampaignSettings'
import { SelectField, SelectFieldOption } from '../../../components/SelectField/SelectField'
import SwitchComponent from '../../../components/Switch/Switch'
import { CampaignSettingsQuery } from './operations/campaign-settings.generated'

import { useToast } from '../../../components/Alert/ToastProvider'
import { useSetCampaignWidgetEnabledMutation } from './operations/set-campaign-widget-enabled.generated'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowX: 'hidden',
    },
    field: {
      marginTop: 20,
      marginBottom: 20,
    },
    label: {
      color: theme.palette.primary.main,
    },
    input: {
      width: '100%',
    },
    caption: {
      color: primary[800],
    },
    switchMargin: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: '15px',
    },
  }),
)

interface ShoppableAmbassadorWidgetSettingsProps {
  program: NonNullable<NonNullable<CampaignSettingsQuery['campaign']>['program']>
}

const widgetPositionOptions: SelectFieldOption[] = [
  { id: EcommWidgetPosition.CenterBottom, label: 'Center Bottom' },
  { id: EcommWidgetPosition.LeftBottom, label: 'Left Bottom' },
  { id: EcommWidgetPosition.LeftCenter, label: 'Left Center' },
  { id: EcommWidgetPosition.RightBottom, label: 'Right Bottom' },
  { id: EcommWidgetPosition.RightCenter, label: 'Right Center' },
]

export default function ShoppableAmbassadorWidgetSettings({
  program,
}: ShoppableAmbassadorWidgetSettingsProps): React.ReactElement {
  const classes = useStyles()

  const { values, setFieldValue } = useFormikContext<ProgramSettingsFields>()
  const [creatorWidgetEnabled, setCreatorWidgetEnabled] = useState(program.ecommIntegration?.creatorWidget)

  const { showToast } = useToast()

  const [setCampaignWidgetEnabled] = useSetCampaignWidgetEnabledMutation({
    onError: () =>
      showToast({
        title: 'Error: Widget Settings',
        message: 'Something went wrong while saving the Widget Settings, please try again.',
        severity: 'error',
        autoHideDuration: 5000,
      }),
    onCompleted: () => {
      setCreatorWidgetEnabled(!creatorWidgetEnabled)
      showToast({
        title: 'Success: Widget Settings Updated',
        message: 'Widget settings were successfully saved.',
        severity: 'success',
        autoHideDuration: 5000,
      })
    },
  })

  return (
    <Box className={classes.container}>
      <Box>
        <Box display="flex" className={classes.field}>
          <Typography variant="h5">Set Up Creator Widget</Typography>
          <SwitchComponent
            checked={creatorWidgetEnabled}
            onChange={() =>
              setCampaignWidgetEnabled({ variables: { value: !creatorWidgetEnabled, programId: program.id } })
            }
            className={classes.switchMargin}
          />
        </Box>
      </Box>
      {creatorWidgetEnabled && (
        <Box className={classes.field}>
          <Typography className={classes.label} variant="body1">
            Widget Position
          </Typography>
          <SelectField
            hideSearch
            placeholder="Widget Position"
            value={values.widgetPosition}
            setValue={(value: string | number) => setFieldValue('widgetPosition', value)}
            options={widgetPositionOptions}
          />
        </Box>
      )}
    </Box>
  )
}
