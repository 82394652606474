import React, { useEffect, useRef, useState } from 'react'
import {
  AccountProductName,
  CustomerFilterInput,
  CustomerSort,
  SortDirection,
  CustomerExportFieldEnum,
  IgMediaPostType,
} from '../gql-global'
import { useCustomersQuery } from './operations/query-customers.generated'
import { useCustomerListUserDataQuery } from './operations/customer-list-user-data.generated'
import { useCustomerCampaignsQuery } from './operations/customer-campaigns.generated'
import { useMessageTemplatesListFilterQuery } from './operations/query-message-templates-list-filter.generated'
import { NetworkStatus } from '@apollo/client'
import CustomerTable from '../components/CustomerTable/CustomerTable'
import useSelectionState from '../hooks/useSelectionState'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@material-ui/core'
import { useDateRangeRef, useDateRangesRef } from '../hooks/useDateRangeRef'
import { invalidSinceRealizedDateRangeFromFilter, realizedDateRangeFromFilter } from '../utils/date-range-helper'
import useFilterParams, { Filters } from './use-filter-params'
import useSortParam from './use-sort-param'
import ListFilters from '../components/lists/ListFilters'
import SyncPendingSnackbar from '../components/SyncPendingSnackbar'
import Page from '../Page'
import ContainerError from '../components/ContainerError'
import LabelMenu from '../components/LabelMenu/LabelMenu'
import ListActions from '../components/lists/ListActions'
import ListActionMenu from '../components/lists/ListActionMenu'
import ListHeader from '../components/lists/ListHeader'
import ListCount from '../components/lists/ListCount'
import { ReactComponent as PlusIcon } from '../icons/plus_minor.svg'
import { ReactComponent as Export } from '../icons/export.svg'
import { AddSegmentMutationVariables, useAddSegmentMutation } from './operations/add-segment.generated'
import {
  CreateSegmentForCustomerMutationVariables,
  useCreateSegmentForCustomerMutation,
} from './operations/create-segment.generated'
import { useExportCustomersLazyQuery } from './operations/export-customers.generated'
import { BULK_SEGMENT_ADD_LIMIT, EXPORT_LIMIT, MESSAGE_TEMPLATES_LIMIT } from './constants'
import useBulkActionState from './use-bulk-action-state'
import useTitle from '../utils/use-title'
import CustomerSavedFilterBar from './CustomerSavedFilterBar'
import { NumberParam, useQueryParam } from 'use-query-params'
import {
  mediaTypeOptions,
  igMentionTypeOptions,
  tagStatusOptions,
  postTypeOptions,
  ttMentionTypeOptions,
  messageSendingMethodOptions,
  challengeApprovalStatusOptions,
} from '../content/constants'
import {
  KeywordsOption,
  Options,
  SelectionOption,
  LabelsOption,
  ParentOption,
  DateRangeOption,
} from '../components/lists/ListFilters/types'
import labelsCacheUpdate from '../mutations/labels-cache-update'
import useSortDirParam from './use-sort-dir-param'
import useIntercomOnPage from '../hooks/useIntercomOnPage'
import { isTypeName } from '../types/utility'
import useHasFeature from '../hooks/useHasFeature'
import { filterMentionTypesSetForAccountType, makeMentionTypesWhere } from '../dashboard/utils'
import { useCustomerSocialAccountQuery } from './operations/social-account.generated'
import { cleanAllAnyNoneFilter, useDateInclusionExclusionWhereFilter } from '../utils/filter-params'
import { useToast } from '../components/Alert/ToastProvider'
import { useSocialAccountChallengesQuery } from './operations/query-social-account-challenges.generated'
import { createCustomFieldFilters } from '../utils/custom-field-filters'
import { LABEL_MANAGEMENT_ROUTE, SEGMENT_MANAGEMENT_ROUTE } from '../settings/routes'

function getBulkActionTexts(action: 'export' | 'segment' | 'addSegment' | 'createSegment'): {
  title: string
  actionText: string
  limitText: string
} {
  if (action === 'export') {
    return {
      title: 'Export',
      actionText: 'export',
      limitText: EXPORT_LIMIT.toLocaleString(),
    }
  }
  return {
    title: 'Add Segment',
    actionText: 'add a segment to',
    limitText: BULK_SEGMENT_ADD_LIMIT.toLocaleString(),
  }
}

const useWhereFilters = (
  filters: Filters,
  isInstagramAccount: boolean,
  hasTikTokHashtags: boolean,
): CustomerFilterInput => {
  const currentMemberSinceDateRange = filters.approvedAt ? realizedDateRangeFromFilter(filters.approvedAt) : null
  const reffedCurrentMemberSinceDateRange = useDateRangeRef(currentMemberSinceDateRange)
  const currentPostedAtDateRange = filters.postedAt ? realizedDateRangeFromFilter(filters.postedAt) : null
  const reffedPostedAtDateRange = useDateRangeRef(currentPostedAtDateRange)
  const currentMaxPostedAtDateRange = filters.maxPostedAt
    ? invalidSinceRealizedDateRangeFromFilter(filters.maxPostedAt)
    : null
  const reffedMaxPostedAtDateRange = useDateRangeRef(currentMaxPostedAtDateRange)
  const currentMinPostedAtDateRange = filters.minPostedAt ? realizedDateRangeFromFilter(filters.minPostedAt) : null
  const reffedMinPostedAtDateRange = useDateRangeRef(currentMinPostedAtDateRange)
  const currentMaxRewardedDateRange = filters.maxRewardedAt ? realizedDateRangeFromFilter(filters.maxRewardedAt) : null
  const reffedCurrentMaxRewardedDateRange = useDateRangeRef(currentMaxRewardedDateRange)
  const currentNoteCreatedAtDateRange = filters.noteCreatedAt
    ? realizedDateRangeFromFilter(filters.noteCreatedAt)
    : null
  const reffedNoteCreatedAtDateRange = useDateRangeRef(currentNoteCreatedAtDateRange)
  const customFieldsAllDates =
    filters.customFields?.all?.map(accf => (accf.dateValue ? realizedDateRangeFromFilter(accf.dateValue) : null)) || []
  const customFieldsAllDateRefs = useDateRangesRef(customFieldsAllDates)
  const customFieldsAll = filters.customFields?.all?.map((f, i) => ({ ...f, dateValue: customFieldsAllDateRefs[i] }))

  const customFieldsAnyDates =
    filters.customFields?.any?.map(accf => (accf.dateValue ? realizedDateRangeFromFilter(accf.dateValue) : null)) || []
  const customFieldsAnyDateRefs = useDateRangesRef(customFieldsAnyDates)
  const customFieldsAny = filters.customFields?.any?.map((f, i) => ({ ...f, dateValue: customFieldsAnyDateRefs[i] }))
  const messageDate = useDateInclusionExclusionWhereFilter(filters.messageDate)
  return {
    approvedAt: reffedCurrentMemberSinceDateRange ?? undefined,
    username: filters.usernameKeywords?.length ? { keywords: filters.usernameKeywords } : null,
    email: filters.emailKeywords?.length ? { keywords: filters.emailKeywords } : null,
    biography: filters.biographyKeywords?.length ? { all: filters.biographyKeywords.map(k => [k]) } : undefined,
    notes: filters.notesKeywords?.length ? { keywords: filters.notesKeywords } : undefined,
    postedAt: reffedPostedAtDateRange,
    maxPostedAt: reffedMaxPostedAtDateRange,
    minPostedAt: reffedMinPostedAtDateRange,
    followerCount: filters.followerCount,
    avgEngagementRate: filters.avgEngagementRate,
    postCount: filters.postCount,
    segments:
      filters.segments?.all || filters.segments?.none
        ? {
            all: filters.segments.all || undefined,
            none: filters.segments.none || undefined,
          }
        : undefined,
    campaigns:
      filters.campaigns && filters.campaigns.any
        ? {
            any: filters.campaigns.any || undefined,
          }
        : undefined,
    challengeIds:
      filters.challengeIds && filters.challengeIds.any
        ? {
            any: filters.challengeIds.any || undefined,
          }
        : undefined,
    labels:
      filters.labels?.all || filters.labels?.none
        ? {
            all: filters.labels.all || undefined,
            none: filters.labels.none || undefined,
          }
        : undefined,
    messageTemplate:
      filters.messageTemplate?.all || filters.messageTemplate?.none
        ? {
            all: filters.messageTemplate.all || undefined,
            none: filters.messageTemplate.none || undefined,
          }
        : undefined,
    messageKeywords: filters.messageKeywords,
    impressions: filters.impressions,
    ...makeMentionTypesWhere(filters.mentionType, isInstagramAccount, hasTikTokHashtags),
    tagStatus: filters.tagStatus?.size ? { any: Array.from(filters.tagStatus) } : undefined,
    challengeMediaApproval: filters.challengeMediaApproval?.size
      ? { any: Array.from(filters.challengeMediaApproval) }
      : undefined,
    mediaType: filters.mediaType?.size ? { any: Array.from(filters.mediaType) } : undefined,
    postType: filters.postType?.size ? { any: Array.from(filters.postType) } : undefined,
    maxRewardedAt: reffedCurrentMaxRewardedDateRange,
    rewards: filters.rewards ? { any: Array.from(filters.rewards) } : undefined,
    noteCategories: filters.noteCategories ? { any: Array.from(filters.noteCategories) } : undefined,
    noteCreatedAt: reffedNoteCreatedAtDateRange,
    hasIgUser: undefined,
    customFields:
      customFieldsAll || customFieldsAny
        ? {
            all: customFieldsAll || undefined,
            any: customFieldsAny || undefined,
          }
        : undefined,
    messageDate,
    messageSendingMethod: cleanAllAnyNoneFilter(filters.messageSendingMethod),
  }
}

const PAGE_SIZE = 10

const CustomerList: React.FC = () => {
  useTitle('Social CRM')
  useIntercomOnPage('CustomerList')
  const { data: userData, error: userDataError, loading: userDataLoading } = useCustomerListUserDataQuery()
  const hasHitSegmentLimit = !!userData?.whoami?.account?.organization.segmentLimit.hasHitLimit
  const customerRewards = userData?.whoami?.account?.rewards
  const socialAccountId = userData?.whoami?.preferences.selectedSocialAccountId
  const [sort = CustomerSort.Impressions, setSort] = useSortParam()
  const [sortDir = SortDirection.Desc, setSortDir] = useSortDirParam()
  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE, setViewing] = useQueryParam('viewing', NumberParam)
  const limitRef = useRef(viewing)
  const { filters, setFilters, isDirty } = useFilterParams()
  const [saveFilterDialogOpen, setSaveFilterDialogOpen] = useState(false)
  const [selectionState, selectionDispatch] = useSelectionState()
  useEffect(() => {
    selectionDispatch({ type: 'reset' })
  }, [filters.usernameKeywords, selectionDispatch])

  const [bulkState, dispatch] = useBulkActionState()
  const { showToast } = useToast()

  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')

  const { data: socialAccountData } = useCustomerSocialAccountQuery({
    skip: !socialAccountId,
    variables: {
      id: socialAccountId || '',
    },
  })
  const isIGSocialAccount =
    socialAccountData?.socialAccount && isTypeName(socialAccountData.socialAccount, 'IGSocialAccount')
  const isTiktokSocialAccount =
    socialAccountData?.socialAccount && isTypeName(socialAccountData.socialAccount, 'TTSocialAccount')

  const cleanedMentionTypes = filterMentionTypesSetForAccountType(
    filters.mentionType,
    isIGSocialAccount ?? false,
    hasTikTokHashtags,
  )
  filters.mentionType = cleanedMentionTypes.size ? cleanedMentionTypes : undefined

  const whereFilters = useWhereFilters(filters, isIGSocialAccount ?? false, hasTikTokHashtags ?? false)

  const mentionsWhere = {
    challengeIds: whereFilters.challengeIds,
    socialAccountId: { any: [socialAccountId || ''] },
    postedAt: whereFilters.postedAt,
    wasTagged: whereFilters.wasTagged,
    wasCaptionMentioned: whereFilters.wasCaptionMentioned,
    wasStoryMentioned: whereFilters.wasStoryMentioned,
    wasHashtagMentioned: whereFilters.wasHashtagMentioned,
    tagStatus: whereFilters.tagStatus,
    mediaType: whereFilters.mediaType,
    postType: whereFilters.postType,
    challengeMediaApproval: whereFilters.challengeMediaApproval,
    labels: whereFilters.labels,
  }
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: { any: [socialAccountId || ''] },
  }

  const {
    loading: customersLoading,
    data,
    fetchMore,
    networkStatus,
    error,
  } = useCustomersQuery({
    skip: !socialAccountId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      sortDirection: sortDir,
      socialAccountId: socialAccountId || '',
      limit: limitRef.current,
      sortBy: sort,
      where: whereFilters,
      mentionsWhere,
      activeStoryMentionsWhere,
    },
  })

  const { data: challengeData } = useSocialAccountChallengesQuery({
    skip: !socialAccountId,
    variables: {
      socialAccountId: socialAccountId || '',
      limit: 200,
    },
  })

  const challenges = (challengeData?.socialAccount?.challenges?.results || []).map(challenge => {
    return { id: challenge.id, name: challenge.name }
  })

  const hasCampaigns = userData?.whoami?.account?.organization.activeProducts.some(
    p => p === AccountProductName.Campaign,
  )

  const { data: campaignData } = useCustomerCampaignsQuery({
    skip: !socialAccountId || !hasCampaigns,
    variables: {
      socialAccountId: socialAccountId || '',
    },
  })

  const noteCategoryOptions =
    userData?.noteCategories && !userDataLoading
      ? userData.noteCategories.map(c => {
          return { id: c.id, label: c.name }
        })
      : []

  const newViewingCount = data?.socialAccount?.customers?.results.length || limitRef.current
  useEffect(() => setViewing((Math.ceil(newViewingCount / PAGE_SIZE) || 1) * PAGE_SIZE), [newViewingCount, setViewing])

  const exportUrlRef = useRef<string | null>(null)
  const [exportCustomers, exportResults] = useExportCustomersLazyQuery()
  const exportUrl = exportResults.data?.socialAccount?.customers?.csvUrl
  useEffect(() => {
    if (exportUrl && exportUrl !== exportUrlRef.current) {
      exportUrlRef.current = exportUrl
      window.location.href = exportUrl
    }
  }, [exportUrl])

  const [addSegment] = useAddSegmentMutation({
    update: labelsCacheUpdate,
  })
  const [createSegment] = useCreateSegmentForCustomerMutation({
    update: labelsCacheUpdate,
  })

  const setCustomerSelectionAllInFilter = (selected: boolean): void => {
    const type = selected ? 'selectAll' : 'reset'
    selectionDispatch({ type })
  }

  const onExport = (): void => {
    if (!selectionState) {
      return
    }
    let where: CustomerFilterInput = {}
    let limit = EXPORT_LIMIT
    if (selectionState === 'ALL') {
      where = whereFilters
    } else {
      where = {
        id: {
          any: Array.from(selectionState).map(s => s.toString()),
        },
      }
      limit = selectionState.size
    }
    const fields = [
      isTiktokSocialAccount ? CustomerExportFieldEnum.TtUsername : CustomerExportFieldEnum.IgUsername,
      CustomerExportFieldEnum.Email,
      isTiktokSocialAccount ? CustomerExportFieldEnum.TtAvatar : CustomerExportFieldEnum.IgAvatar,
      CustomerExportFieldEnum.MaxPostDate,
      CustomerExportFieldEnum.PostCount,
      isTiktokSocialAccount ? CustomerExportFieldEnum.TtFollowers : CustomerExportFieldEnum.IgFollowers,
      CustomerExportFieldEnum.AvgEngagementRate,
      CustomerExportFieldEnum.Impressions,
      // Here we are asking for the other account, your IG if you are on TikTok, and viceversa
      isTiktokSocialAccount ? CustomerExportFieldEnum.IgUsername : CustomerExportFieldEnum.TtUsername,
      CustomerExportFieldEnum.Address,
      CustomerExportFieldEnum.Address_2,
      CustomerExportFieldEnum.City,
      CustomerExportFieldEnum.State,
      CustomerExportFieldEnum.Country,
      CustomerExportFieldEnum.Zip,
      CustomerExportFieldEnum.Phone,
      CustomerExportFieldEnum.DiscountCodes,
      CustomerExportFieldEnum.StorefrontUrl,
      CustomerExportFieldEnum.ExternalId,
    ]

    exportCustomers({
      variables: {
        socialAccountId: socialAccountId || '',
        where,
        mentionsWhere,
        limit,
        sortBy: sort,
        sortDirection: SortDirection.Desc,
        fields,
      },
    })
  }

  const loadMoreCustomers = (): void => {
    const nextCursor = data?.socialAccount?.customers?.cursor

    if (nextCursor) {
      fetchMore({
        variables: {
          cursor: nextCursor,
          limit: PAGE_SIZE,
        },
      })
    }
  }

  function handleSetFilters(newFilters: Filters): void {
    limitRef.current = PAGE_SIZE
    selectionDispatch({ type: 'reset' })
    const wipeIgFilters = {
      ...(!hasTikTokHashtags ? { mentionType: undefined } : {}),
      postType: undefined,
    }
    const validFilters = {
      ...newFilters,
      ...(isIGSocialAccount ? {} : wipeIgFilters),
    }
    setFilters(validFilters)
  }

  const list = data?.socialAccount?.customers?.results || []
  const customerCount = data?.socialAccount?.customers?.total
  const accountSegments = userData?.whoami?.account?.segments?.results || []

  const accountCampaigns =
    campaignData?.socialAccount?.campaigns &&
    (campaignData?.socialAccount?.campaigns).map(c => {
      return { id: c.id, name: c.program?.name || '' }
    })

  const segmentIdsAlreadyOnAllSelectedCustomers = new Set<number>()
  if (selectionState instanceof Set) {
    accountSegments.forEach(segment => {
      const selectedCustomersInThatSegment = list.filter(
        c => selectionState.has(c.id) && c.segments?.some(s => s.id === segment.id),
      )
      if (selectedCustomersInThatSegment.length > 0 && selectedCustomersInThatSegment.length === selectionState.size) {
        segmentIdsAlreadyOnAllSelectedCustomers.add(segment.id)
      }
    })
  }

  const onAddSegment = (segmentId: number): void => {
    if (!selectionState) return
    let affectedIgUsersCount = 0

    let variables: AddSegmentMutationVariables = {
      sortDirection: SortDirection.Desc,
      sortBy: sort,
      limit: BULK_SEGMENT_ADD_LIMIT,
      segmentId: segmentId.toString(),
    }
    if (selectionState === 'ALL') {
      affectedIgUsersCount =
        customerCount && customerCount < BULK_SEGMENT_ADD_LIMIT ? customerCount : BULK_SEGMENT_ADD_LIMIT
      variables = { ...variables, socialAccountId: socialAccountId || '', where: whereFilters }
    } else {
      const customerIds = Array.from(selectionState)
        .slice(0, BULK_SEGMENT_ADD_LIMIT)
        .map(id => id.toString())
      variables = { ...variables, customerIds }
      affectedIgUsersCount = customerIds.length
    }

    addSegment({
      variables: variables,
    })
      .then(() => {
        const segmentName = accountSegments.find(s => s.id === segmentId)?.name
        showToast({
          title: 'Success: Applying Segment',
          message: `Segment '${segmentName}' was applied to ${affectedIgUsersCount || ''} customer${
            affectedIgUsersCount === 1 ? '' : 's'
          }.`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Adding Segment',
          message: 'Something went wrong when adding this segment to the customer(s), please try again.',
        })
      })
  }

  const onCreateSegment = (name: string): void => {
    if (!selectionState) return
    let affectedIgUsersCount = 0

    let variables: CreateSegmentForCustomerMutationVariables = {
      sortDirection: SortDirection.Desc,
      sortBy: sort,
      limit: BULK_SEGMENT_ADD_LIMIT,
      name: name,
    }
    if (selectionState === 'ALL') {
      affectedIgUsersCount =
        customerCount && customerCount < BULK_SEGMENT_ADD_LIMIT ? customerCount : BULK_SEGMENT_ADD_LIMIT
      variables = { ...variables, socialAccountId: socialAccountId || '', where: whereFilters }
    } else {
      const customerIds = Array.from(selectionState)
        .slice(0, BULK_SEGMENT_ADD_LIMIT)
        .map(id => id.toString())
      variables = { ...variables, customerIds }
      affectedIgUsersCount = customerIds.length
    }

    createSegment({
      variables: variables,
    })
      .then(() => {
        showToast({
          title: 'Success: Creating Segment',
          message: `Segment '${name}' was created and applied to ${affectedIgUsersCount} customer${
            affectedIgUsersCount === 1 ? '' : 's'
          }.`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Creating Segment',
          message: 'Something went wrong when creating this label, please try again.',
        })
      })
  }

  const onConfirmBulkAction = (): void => {
    if (bulkState?.step !== 'confirmation') {
      return
    }

    switch (bulkState.action) {
      case 'export':
        onExport()
        break
      case 'addSegment':
        onAddSegment(bulkState.id)
        break
      case 'createSegment':
        onCreateSegment(bulkState.name)
        break
    }
    dispatch({ type: 'done' })
  }

  const ready =
    !userDataLoading && !(customersLoading && networkStatus !== NetworkStatus.fetchMore) && !!socialAccountId
  const hasError = error || userDataError

  const customFields = userData?.whoami?.account?.customFields || []
  const customFieldsFilterConfig = createCustomFieldFilters(customFields)

  const { data: messageTemplatesListData } = useMessageTemplatesListFilterQuery({
    skip: !socialAccountId,
    variables: {
      socialAccountId: socialAccountId || '',
      limit: MESSAGE_TEMPLATES_LIMIT,
    },
  })

  const messageTemplates =
    messageTemplatesListData?.socialAccount &&
    isTypeName(messageTemplatesListData?.socialAccount, 'IGSocialAccount') &&
    messageTemplatesListData?.socialAccount?.messageTemplates
  const messageTemplatesList = messageTemplates ? messageTemplates.results : []

  const accountLabels = userData?.whoami?.account?.labels?.results || []

  let customerOptions: Options = [
    {
      name: 'postedAt',
      type: 'dateRange',
      label: 'Post Date',
      entity: 'normal',
    },
    {
      name: 'segments',
      type: 'labels' as const,
      label: 'Segments',
      entity: 'segment' as const,
      selectionOptions: accountSegments,
      includeField: 'all' as const,
      seeAllLink: SEGMENT_MANAGEMENT_ROUTE.path,
    },
    ...(hasCampaigns && accountCampaigns && accountCampaigns.length > 0
      ? [
          {
            name: 'campaigns',
            type: 'labels',
            label: 'Programs',
            entity: 'program',
            selectionOptions: accountCampaigns,
            includeField: 'any' as const,
          } as LabelsOption,
        ]
      : []),
    {
      name: 'challengeIds',
      type: 'labels',
      label: 'Challenges',
      entity: 'challenge',
      selectionOptions: challenges,
      includeField: 'any' as const,
    } as LabelsOption,
    {
      name: 'messagesGroup',
      type: 'parent' as const,
      label: 'Messages',
      children: [
        {
          name: 'messageTemplate',
          type: 'labels' as const,
          label: 'Template Name ',
          entity: 'message' as const,
          selectionOptions: messageTemplatesList,
          includeField: 'all' as const,
        },
        {
          name: 'messageDate',
          type: 'dateRange',
          label: 'Message Date',
          entity: 'normalPlusHours',
          exclude: true,
        } as DateRangeOption,
        {
          name: 'messageKeywords',
          type: 'keywords' as const,
          label: 'Message Keywords ',
          includeField: 'all' as const,
          useChips: true,
          allowSpaces: true,
        },
        {
          name: 'messageSendingMethod',
          type: 'selection',
          label: 'Sending Method',
          selectionOptions: messageSendingMethodOptions,
          exclude: true,
        } as SelectionOption,
      ],
    },
    {
      name: 'usernameKeywords',
      type: 'keywords',
      label: 'Username',
      useChips: true,
      operator: 'or',
    },
    {
      name: 'emailKeywords',
      type: 'keywords',
      label: 'Email',
      useChips: true,
      operator: 'or',
    },
    {
      name: 'biographyKeywords',
      type: 'keywords',
      label: 'Biography',
      useChips: true,
      allowSpaces: true,
    },
    {
      name: 'noteInfo',
      type: 'parent',
      label: 'Notes',
      children: [
        {
          name: 'notesKeywords',
          type: 'keywords',
          label: 'Note Keyword',
          useChips: true,
          allowSpaces: true,
        } as KeywordsOption,
        {
          name: 'noteCategories',
          type: 'selection',
          label: 'Note Category',
          selectionOptions: noteCategoryOptions,
        } as SelectionOption,
        {
          name: 'noteCreatedAt',
          type: 'dateRange',
          label: 'Note Date',
          entity: 'normal',
        },
      ],
    } as ParentOption,
    {
      name: 'customerInfo',
      type: 'parent',
      label: 'Customer Info',
      children: [
        {
          name: 'followerCount',
          type: 'numericalRange',
          label: 'Follower Count',
          min: 0,
          max: 1_000_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
        {
          name: 'avgEngagementRate',
          type: 'numericalRange',
          label: 'Engagement Rate',
          min: 0,
          max: 300,
          sliderRange: {
            gte: 0,
            lte: 150,
          },
          numberFormat: 'percent',
        },
        {
          name: 'impressions',
          type: 'numericalRange',
          label: 'Impressions',
          min: 0,
          max: 300_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
      ],
    },
    {
      name: 'activity',
      type: 'parent',
      label: 'Activity',
      children: [
        ...(hasCampaigns && accountCampaigns && accountCampaigns.length > 0
          ? [{ name: 'approvedAt', type: 'dateRange', label: 'Member Since Date', entity: 'normal' } as DateRangeOption]
          : []),
        {
          name: 'postCount',
          type: 'numericalRange',
          label: 'Post Count',
          min: 0,
          max: 5000,
          sliderRange: {
            gte: 0,
            lte: 1000,
          },
        },
        {
          name: 'minPostedAt',
          type: 'dateRange',
          label: 'First Post Date',
          entity: 'normal',
        },
        {
          name: 'maxPostedAt',
          type: 'dateRange',
          label: 'Inactive Since',
          entity: 'inactiveFilter',
        },
        { name: 'maxRewardedAt', type: 'dateRange', label: 'Last Reward Date', entity: 'normal' },
        {
          name: 'rewards',
          type: 'selection',
          label: 'Reward',
          selectionOptions: customerRewards
            ? customerRewards?.results.map(r => ({
                id: r.id.toString(),
                label: r.name || `Reward ${r.id.toString()}`,
              }))
            : [],
        },
      ],
    },
    {
      name: 'postInfo',
      type: 'parent',
      label: 'Post Info',
      children: [
        ...(isIGSocialAccount || hasTikTokHashtags
          ? [
              {
                name: 'mentionType',
                type: 'selection',
                label: 'Mention Type',
                selectionOptions: isIGSocialAccount ? igMentionTypeOptions : ttMentionTypeOptions,
              } as SelectionOption,
            ]
          : []),
        {
          name: 'mediaType',
          type: 'selection',
          label: 'Media Type',
          selectionOptions: mediaTypeOptions,
        } as SelectionOption,
        ...(isIGSocialAccount
          ? [
              {
                name: 'postType',
                type: 'selection',
                label: 'Post Type',
                selectionOptions: postTypeOptions,
              } as SelectionOption,
            ]
          : []),
        {
          name: 'labels',
          type: 'labels' as const,
          label: 'Labels',
          entity: 'label' as const,
          selectionOptions: accountLabels,
          includeField: 'all' as const,
          seeAllLink: LABEL_MANAGEMENT_ROUTE.path,
        },
      ],
    },
    {
      name: 'postStatus',
      type: 'parent',
      label: 'Post Status',
      children: [
        {
          name: 'tagStatus',
          type: 'selection',
          label: 'Program Status',
          selectionOptions: tagStatusOptions,
        } as SelectionOption,
        {
          name: 'challengeMediaApproval',
          type: 'selection',
          label: 'Challenge Status',
          selectionOptions: challengeApprovalStatusOptions,
        },
      ],
    },
  ]

  if (customFields && customFields.length > 0) {
    customerOptions = [
      ...customerOptions,
      {
        name: 'customFields',
        type: 'parent',
        label: 'Custom Field Data',
        children: customFieldsFilterConfig,
      } as ParentOption,
    ]
  }

  const excludeOptions = [
    {
      name: 'segments',
      type: 'labels' as const,
      label: 'Segments',
      entity: 'segment' as const,
      selectionOptions: accountSegments,
      seeAllLink: SEGMENT_MANAGEMENT_ROUTE.path,
    },
    {
      name: 'messagesGroup',
      type: 'parent' as const,
      label: 'Messages',
      children: [
        {
          name: 'messageTemplate',
          type: 'labels' as const,
          label: 'Template Name ',
          entity: 'message' as const,
          selectionOptions: messageTemplatesList,
        },
        {
          name: 'messageDate',
          type: 'dateRange',
          label: 'Message Date',
          entity: 'normalPlusHours',
          exclude: true,
        } as DateRangeOption,
        {
          name: 'messageKeywords',
          type: 'keywords' as const,
          label: 'Message Keywords ',
          useChips: true,
          allowSpaces: true,
        },
        {
          name: 'messageSendingMethod',
          type: 'selection',
          label: 'Sending Method',
          selectionOptions: messageSendingMethodOptions,
          exclude: true,
        } as SelectionOption,
      ],
    },
    {
      name: 'labels',
      type: 'labels' as const,
      label: 'Labels',
      entity: 'label' as const,
      selectionOptions: accountLabels,
      seeAllLink: LABEL_MANAGEMENT_ROUTE.path,
    },
  ]

  return (
    <Page>
      {socialAccountId && <SyncPendingSnackbar socialAccountId={socialAccountId} />}
      <div data-intercom-target="Customer Saved Filter Bar">
        <CustomerSavedFilterBar
          filters={filters}
          setFilters={handleSetFilters}
          accountId={userData?.whoami?.account?.id}
          accountFilters={userData?.whoami?.account?.customerFilters || []}
          isInstagramAccount={isIGSocialAccount ?? false}
          setSaveFilterDialogOpen={setSaveFilterDialogOpen}
          saveFilterDialogOpen={saveFilterDialogOpen}
        />
      </div>
      <div data-intercom-target="Customer Filters">
        <ListFilters
          contentType="customers"
          isDirty={isDirty}
          onChangeFilters={handleSetFilters}
          options={customerOptions}
          excludeOptions={excludeOptions}
          filters={filters}
          setSaveFilterDialogOpen={setSaveFilterDialogOpen}
        />
      </div>
      <ListHeader>
        <Box mr={2}>
          <ListCount loading={!ready} count={customerCount} units="Customers" />
        </Box>
        {selectionState !== 'ALL' && (
          <Button
            data-intercom-target="Customer Select All Button"
            variant="text"
            color="primary"
            onClick={() => setCustomerSelectionAllInFilter(true)}
          >
            Select All {customerCount?.toLocaleString()}
          </Button>
        )}
        {selectionState === 'ALL' && (
          <Button variant="text" color="secondary" onClick={() => setCustomerSelectionAllInFilter(false)}>
            All {customerCount?.toLocaleString()} Selected
          </Button>
        )}
        <Box flexGrow={1} />
        <ListActions>
          {!!selectionState && !!data?.socialAccount?.customers?.results.length && (
            <ListActionMenu
              actions={[
                { action: 'segment', label: 'Segment', icon: <PlusIcon width={16} /> },
                { action: 'export', label: 'Export', icon: <Export /> },
              ]}
              onSelectAction={action => {
                dispatch({
                  type: 'start',
                  action,
                  selectedCount: (selectionState === 'ALL' ? customerCount : selectionState?.size) || 0,
                })
              }}
            />
          )}
        </ListActions>
      </ListHeader>
      <CustomerTable
        hasCampaigns={hasCampaigns}
        entity="customer"
        list={list}
        ready={ready}
        loadingMore={networkStatus === NetworkStatus.fetchMore}
        sort={sort}
        sortDir={sortDir}
        setSort={(x: CustomerSort) => {
          if (x === sort) {
            const sortDirection = sortDir === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc
            setSortDir(sortDirection)
          }
          selectionDispatch({ type: 'reset' })
          setSort(x)
        }}
        limit={limitRef.current}
        useCustomerPageLinks
        multiSelect
        selectionDispatch={selectionDispatch}
        selectionState={selectionState}
        isIGSocialAccount={isIGSocialAccount}
      />
      {ready && data?.socialAccount?.customers?.cursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={loadMoreCustomers}
            disabled={networkStatus === NetworkStatus.fetchMore}
          >
            Load more
          </Button>
        </Box>
      )}
      {hasError && (
        <Box display="flex" justifyContent="center">
          <ContainerError text="Error loading customers." />
        </Box>
      )}
      <LabelMenu
        editable
        entity="segment"
        seeAllLink={SEGMENT_MANAGEMENT_ROUTE.path}
        open={bulkState?.step === 'pickSegment'}
        variant="dialog"
        labels={userData?.whoami?.account?.segments?.results || []}
        onSelect={id => {
          dispatch({ type: 'selectedSegment', id })
        }}
        onCreate={(name: string) => {
          dispatch({ type: 'createSegment', name })
        }}
        onUpdate={() => {
          dispatch({ type: 'done' })
        }}
        onDelete={() => {
          dispatch({ type: 'done' })
        }}
        hasHitLimit={hasHitSegmentLimit}
        onCancel={() => dispatch({ type: 'done' })}
        selectedLabelIds={segmentIdsAlreadyOnAllSelectedCustomers}
        allowedActions={[]}
      />
      <Dialog open={bulkState?.step === 'overLimit'} onClose={() => dispatch({ type: 'done' })}>
        {bulkState?.step === 'overLimit' && (
          <>
            <DialogTitle>Cannot {getBulkActionTexts(bulkState.action).title}</DialogTitle>
            <DialogContent>
              <p>
                You cannot {getBulkActionTexts(bulkState.action).actionText} to more than{' '}
                {getBulkActionTexts(bulkState.action).limitText} customers at a time. Please select under{' '}
                {getBulkActionTexts(bulkState.action).limitText} customers or make a bulk request to{' '}
                <Link href="mailto:support@loudcrowd.com">support@loudcrowd.com</Link>
              </p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={() => dispatch({ type: 'done' })}>
                OK
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog open={bulkState?.step === 'confirmation'} onClose={() => dispatch({ type: 'done' })}>
        {bulkState?.step === 'confirmation' && (
          <>
            <DialogTitle>{getBulkActionTexts(bulkState.action).title}?</DialogTitle>
            <DialogContent>
              <p>
                You are about to {getBulkActionTexts(bulkState.action).actionText}{' '}
                {((selectionState === 'ALL' ? customerCount : selectionState?.size) || 0).toLocaleString()} customers.
              </p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => dispatch({ type: 'done' })}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={onConfirmBulkAction}>
                OK
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Page>
  )
}
export default CustomerList
